/* Poppins */
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/google_fonts/Poppins/Poppins-Light.woff2') format('woff2'),
  url('../fonts/google_fonts/Poppins/Poppins-Light.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/google_fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
  url('../fonts/google_fonts/Poppins/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/google_fonts/Poppins/Poppins-Italic.woff2') format('woff2'),
  url('../fonts/google_fonts/Poppins/Poppins-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/google_fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
  url('../fonts/google_fonts/Poppins/Poppins-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/google_fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
  url('../fonts/google_fonts/Poppins/Poppins-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/google_fonts/Poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/google_fonts/Poppins/Poppins-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/google_fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
  url('../fonts/google_fonts/Poppins/Poppins-Bold.woff') format('woff');
}